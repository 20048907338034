<template>
  <div>
    <h3 class="title-header mt-4">
      {{ $getVisibleNames("teaching.evaluation", true, "Evaluaciones") }}
    </h3>
    <b-button
      v-if="allows_crud && user.is_professor"
      variant="primary"
      class="btn-add-evaluation"
      size="sm"
      :title="`Agregar ${$getVisibleNames(
        'teaching.evaluation',
        false,
        'Evaluación'
      )}`"
      @click="$bvModal.show(`modal-create-evaluation-${egressProfileMatte.id}`)"
      >Crear {{ $getVisibleNames("teaching.evaluation", false, "Evaluación") }}
    </b-button>
    <div v-if="evaluationList">
      <GenericBTable
        :fields="evaluationFields"
        :items="sortList"
        :filter="input_search"
        :pagination="sortList.length"
        :show_pagination="true"
        :search_filter="true"
        :columns_display="true"
        :allows_crud="allows_crud"
        :display_id="'EvaluationTable'"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template #cell(description)="row">
          <div
            v-html="row.item.evaluation.description"
            class="rich-text-content"
          ></div>
        </template>
        <template #cell(evaluation.preferred_weighing)="row">
          <div
            style="text-align: -webkit-center"
            v-if="row.item.evaluation.type == 3"
          >
            <span
              v-for="item in row.item.evaluation.section_evaluations"
              :key="item.id"
            >
              <div class="campus-div">
                {{ item.section_name }} - {{ item.weighing }}%
              </div>
            </span>
          </div>
          <div style="text-align: -webkit-center" v-else>
            <div class="campus-div">N/A</div>
          </div>
        </template>
        <template #cell(evaluation.weighing)="row">
          <div>{{ row.item.evaluation.weighing }}%</div>
        </template>
        <template #cell(instrument)="row">
          <div>
            <b-table-simple
              bordered
              small
              responsive
              hover
              v-if="
                row.item.evaluation.question_tests.length > 0 ||
                row.item.evaluation.observation_instruments.length > 0
              "
            >
              <b-thead>
                <b-tr>
                  <b-th class="th-class">Título</b-th>
                  <b-th class="th-class">Tipo</b-th>
                  <b-th class="th-class">Puntaje</b-th>
                  <b-th class="th-class">Ponderación</b-th>
                  <b-th class="th-class">Sub-tipo</b-th>
                  <b-th class="th-class">Fecha de inicio</b-th>
                  <b-th class="th-class">
                    <template>
                      <button-add
                        v-if="allows_crud"
                        title="Crear Instrumento"
                        v-b-tooltip.v-secondary.left.noninteractive="
                          'Crear Instrumento'
                        "
                        @click="
                          $bvModal.show(
                            `modal-create-instrument-${row.item.evaluation.id}`
                          )
                        "
                        size="sm"
                      >
                      </button-add>
                      <b-modal
                        :id="`modal-create-instrument-${row.item.evaluation.id}`"
                        title="Crear Instrumento"
                        hide-footer
                        size="lg"
                        @hide="() => (selected_instrument_type = null)"
                      >
                        <b-form-select
                          :options="instrument_types"
                          v-model="selected_instrument_type"
                          class="mb-2"
                          text-field="name"
                        >
                          <template #first>
                            <b-form-select-option value="null" disabled>
                              Seleccione un tipo de Instrumento...
                            </b-form-select-option>
                          </template>
                        </b-form-select>
                        <template v-if="selected_instrument_type == 1">
                          <QuestionTestForm
                            :TestForm="true"
                            :evaluation_id="row.item.evaluation.id"
                            @created="CreatedQuestionTest"
                          ></QuestionTestForm>
                        </template>
                        <template v-if="selected_instrument_type == 2">
                          <ObservationInstrumentForm
                            :TestForm="false"
                            :evaluation_id="row.item.evaluation.id"
                            @created="CreatedObservationInstrument"
                          ></ObservationInstrumentForm
                        ></template>
                      </b-modal>
                    </template>
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr
                  v-for="item in row.item.evaluation.question_tests"
                  :key="'question_tests' + item.id"
                >
                  <b-td class="td-class">
                    {{ item.title }}
                  </b-td>
                  <b-td class="td-class">
                    <div
                      class="div-href"
                      @click="
                        redirectToQuestionTest(row.item.evaluation.id, item.id)
                      "
                    >
                      Instrumento Escrito
                    </div>
                  </b-td>
                  <b-td class="td-class">
                    {{ item.score }}
                  </b-td>
                  <b-td class="td-class">
                    {{
                      instrumentWeighing(item.score, row.item.evaluation)
                        | Round
                    }}
                    %
                  </b-td>
                  <b-td class="td-class">
                    <span
                      v-b-tooltip.v-secondary.left.noninteractive="'Sub-Tipo'"
                    >
                      <span v-if="item.question_test_sub_type">
                        {{ item.question_test_sub_type }}
                      </span>
                      <span v-else> N/A </span>
                    </span>
                  </b-td>
                  <b-td class="td-class">
                    <span v-if="item.start_date">
                      {{ item.start_date | FormatToDateTime }} </span
                    ><span v-else>No cuenta con Fecha de inicio.</span>
                  </b-td>
                  <b-td class="td-class">
                    <button-edit
                      size="sm"
                      v-if="allows_crud"
                      @click="editInstrument(item.id, 'question_tests')"
                      v-b-tooltip.v-secondary.topright.noninteractive="
                        'Editar Instrumento'
                      "
                    ></button-edit>
                    <!-- class="evaluation-edit mb-2" -->
                    <button-delete
                      v-if="allows_crud"
                      size="sm"
                      @click="
                        askForDeleteInstrument(
                          row.item.evaluation.id,
                          'question_test',
                          item.id
                        )
                      "
                      v-b-tooltip.v-secondary.topright.noninteractive="
                        'Eliminar Instrumento'
                      "
                    ></button-delete>
                    <b-modal
                      :id="`modal-edit-question-test-${item.id}`"
                      title="Editar Instrumento"
                      size="lg"
                      @hide="hideModal"
                      hide-footer
                    >
                      <QuestionTestForm
                        :QuestionTest="selected_instrument"
                        :TestForm="true"
                        :evaluation_id="row.item.evaluation.id"
                        :show_delete_button="false"
                        @updated="slotQuestionTestUpdated"
                      ></QuestionTestForm>
                    </b-modal>
                    <!-- @stateChanged="slotStateChanged" -->
                    <!-- class="evaluation-delete mb-2" -->
                  </b-td>
                </b-tr>
                <b-tr
                  v-for="item in row.item.evaluation.observation_instruments"
                  :key="'observation_instruments' + item.id"
                >
                  <b-td class="td-class">
                    {{ item.title }}
                  </b-td>
                  <b-td class="td-class">
                    <div
                      class="div-href"
                      @click="
                        redirectToObservationInstrument(
                          row.item.evaluation.id,
                          item.id
                        )
                      "
                    >
                      Instrumento de Observación
                    </div>
                  </b-td>
                  <b-td class="td-class">
                    {{ item.score }}
                  </b-td>
                  <b-td class="td-class">
                    {{
                      instrumentWeighing(item.score, row.item.evaluation)
                        | Round
                    }}
                    %
                  </b-td>
                  <b-td class="td-class">
                    <span
                      v-b-tooltip.v-secondary.left.noninteractive="'Sub-Tipo'"
                    >
                      <span v-if="item.observation_instrument_sub_type">
                        {{ item.observation_instrument_sub_type }}
                      </span>
                      <span v-else> N/A </span>
                    </span>
                  </b-td>
                  <b-td class="td-class">
                    <span v-if="item.start_date">
                      {{ item.start_date | FormatToDateTime }} </span
                    ><span v-else>No cuenta con Fecha de inicio.</span>
                  </b-td>
                  <b-td class="td-class">
                    <button-edit
                      size="sm"
                      v-if="allows_crud"
                      @click="
                        editInstrument(item.id, 'observation_instruments')
                      "
                      v-b-tooltip.v-secondary.topright.noninteractive="
                        'Editar Instrumento'
                      "
                    ></button-edit>
                    <!-- class="evaluation-edit mb-2" -->
                    <button-delete
                      v-if="allows_crud"
                      size="sm"
                      @click="
                        askForDeleteInstrument(
                          row.item.evaluation.id,
                          'observation_instrument',
                          item.id
                        )
                      "
                      v-b-tooltip.v-secondary.topright.noninteractive="
                        'Eliminar Instrumento'
                      "
                    ></button-delete>
                    <b-modal
                      :id="`modal-edit-observation-instrument-${item.id}`"
                      title="Editar Instrumento"
                      size="lg"
                      @hide="hideModal"
                      hide-footer
                    >
                      <ObservationInstrumentForm
                        :TestForm="false"
                        :Rubric="selected_instrument"
                        :evaluation_id="row.item.evaluation.id"
                        :show_delete_button="false"
                        @updated="slotObservationInstrumentUpdated"
                      ></ObservationInstrumentForm>
                    </b-modal>
                    <!-- class="evaluation-delete mb-2" -->
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </template>
        <template #cell(actions)="row">
          <!-- <b-icon
            class="btn-actions mr-3 ml-1"
            icon="eye"
            v-b-tooltip.v-secondary.left.noninteractive="
              `Ir a los Instrumentos`
            "
            @click="redirectToInstrument(row.item.evaluation.id)"
            scale="1.2"
          ></b-icon> -->
          <b-button
            size="sm"
            @click="row.toggleDetails"
            class="btn-details mb-2 noprint"
            variant="none"
            v-if="user.is_professor"
          >
            {{ row.detailsShowing ? "Ocultar" : "Mostrar" }} Detalles
          </b-button>
          <b-button
            size="sm"
            class="btn-instrument mb-2 noprint"
            variant="none"
            @click="redirectToInstrument(row.item.evaluation.id)"
            v-if="user.is_professor"
          >
            Ir a los Instrumentos
          </b-button>

          <button-edit
            :title="`Editar ${$getVisibleNames(
              'teaching.evaluation',
              false,
              'Evaluación'
            )}`"
            size="sm"
            class="evaluation-edit mb-2"
            v-can="'teaching.change_evaluation'"
            v-if="allows_crud"
            @click="
              $bvModal.show(`modal-edit-evaluation-${row.item.evaluation.id}`)
            "
            v-b-tooltip.v-secondary.topright.noninteractive="
              `Editar ${$getVisibleNames(
                'teaching.evaluation',
                false,
                'Evaluación'
              )}.`
            "
          ></button-edit>
          <button-delete
            v-if="allows_crud"
            size="sm"
            class="evaluation-delete mb-2"
            v-can="'teaching.delete_evaluation'"
            @click="askForDeleteEvaluation(row.item.evaluation.id)"
            v-b-tooltip.v-secondary.topright.noninteractive="
              `Eliminar ${$getVisibleNames(
                'teaching.evaluation',
                false,
                'Evaluación'
              )}.`
            "
          ></button-delete>
          <b-modal
            v-if="allows_crud"
            :id="`modal-edit-evaluation-${row.item.evaluation.id}`"
            :title="`Editar ${$getVisibleNames(
              'teaching.evaluation',
              false,
              'Evaluación'
            )}`"
            hide-footer
            size="lg"
          >
            <EvaluationTableForm
              :Evaluation="row.item.evaluation"
              :egressProfileMatte="egressProfileMatte"
              :SectionEvaluations="row.item.evaluation.section_evaluations"
              @updated="UpdateEvaluation"
            ></EvaluationTableForm>
          </b-modal>
        </template>
        <template #row-details="row">
          <b-card bg-variant="light">
            <div class="d-flex">
              <div class="w-25 text-left pt-4 pr-1 card-left">
                <div>
                  <strong>Tipo:</strong> {{ row.item.evaluation.type_name }}
                </div>
                <!-- <div>
                  <strong>Categoria:</strong>
                  {{ row.item.evaluation.category_name }}
                </div> -->
                <div>
                  <strong>Editores:</strong>
                  <span
                    v-for="item in row.item.evaluation.editors"
                    :key="item.id"
                  >
                    <div class="campus-div">
                      {{ item.first_name }} {{ item.last_name }}
                    </div>
                  </span>
                </div>
              </div>
              <div class="w-75 ml-4">
                <b-tabs content-class="mt-3" lazy>
                  <b-tab
                    :title="section.section_name"
                    v-for="section in row.item.evaluation.section_evaluations"
                    :key="section.id"
                  >
                    <div class="d-flex ml-3">
                      <div class="left-panel text-left pb-1">
                        <div>
                          <b-form-checkbox
                            class="mr-1 mt-1"
                            switch
                            v-model="section.show_to_students"
                            @change="patchSectionEvaluation(section)"
                            size="sm"
                            :disabled="!allows_crud"
                            >Mostrar a los estudiantes</b-form-checkbox
                          >
                        </div>
                        <div>
                          <b-form-checkbox
                            class="mr-1 mt-1"
                            switch
                            v-model="section.show_in_dashboard"
                            @change="patchSectionEvaluation(section)"
                            size="sm"
                            :disabled="!allows_crud"
                          >
                            Mostrar en dashboard</b-form-checkbox
                          >
                        </div>
                        <div>
                          <b-form-checkbox
                            class="mr-1 mt-1"
                            switch
                            v-model="section.show_stats"
                            @change="patchSectionEvaluation(section)"
                            size="sm"
                            :disabled="!allows_crud"
                          >
                            Mostrar estadísticas
                          </b-form-checkbox>
                        </div>
                      </div>
                      <div class="right-panel text-left pb-1">
                        <div v-if="row.item.evaluation.type == 3">
                          <div v-if="allows_crud" class="w-50">
                            <div class="form-input-container">
                              <span class="label-weighing">Ponderación:</span>
                              <div>
                                <div class="d-flex">
                                  <b-form-input
                                    id="input-weighing"
                                    v-model="section.weighing"
                                    :state="
                                      section.weighing >= 0 &&
                                      getMaxWeighing(section) >= 0 &&
                                      section.weighing <= 100
                                    "
                                    @input="patchSectionEvaluation(section)"
                                    type="number"
                                    min="0"
                                    :max="getMaxWeighing(section)"
                                  ></b-form-input>
                                  <template v-if="spinner_state">
                                    <b-spinner
                                      class="weighing-spinner"
                                      variant="info"
                                    ></b-spinner>
                                  </template>
                                </div>
                                <b-form-invalid-feedback
                                  id="input-weighing-feedback"
                                >
                                  <span v-if="section.weighing > 100">
                                    Este campo debe ser menor a 100
                                  </span>
                                  <span v-else>
                                    Este campo debe estar entre 0 y
                                    {{
                                      isNaN(parseInt(section.weighing))
                                        ? 0 + getMaxWeighing(section)
                                        : parseInt(section.weighing) +
                                          getMaxWeighing(section)
                                    }}.
                                  </span>
                                </b-form-invalid-feedback>
                              </div>
                            </div>
                          </div>
                          <span v-else
                            ><strong>Ponderación: </strong>
                            {{ section.weighing }}%
                          </span>
                        </div>
                        <div
                          v-if="
                            institution &&
                            institution.show_evaluation_criteria_macro
                          "
                        >
                          <span>
                            {{
                              $getVisibleNames(
                                "teaching.evaluationcriteriamacro",
                                true,
                                "Criterios de Evaluación Macro"
                              )
                            }}:</span
                          >
                          <b-button size="sm" class="ml-1 m-0" variant="none">
                            <template v-if="filterECMacro.length > 0">
                              <b-icon
                                class="btn-actions"
                                :icon="allows_crud ? 'pencil-square' : 'eye'"
                                @click="
                                  $bvModal.show(
                                    `evaluation-criteria-macro-modal-${row.item.evaluation.id}-${section.id}`
                                  )
                                "
                                v-b-tooltip.v-secondary.noninteractive="
                                  `${
                                    allows_crud ? 'Seleccionar' : 'Ver'
                                  } ${$getVisibleNames(
                                    'teaching.evaluationcriteriamacro',
                                    true,
                                    'Criterios de Evaluación Macro'
                                  )} ${allows_crud ? '' : 'Seleccionados'}`
                                "
                                scale="1"
                              ></b-icon>
                            </template>
                            <template v-else>
                              <span
                                v-b-tooltip.v-secondary.noninteractive="
                                  `La ${$getVisibleNames(
                                    'mesh.egressprofilematter',
                                    false,
                                    'Asignatura'
                                  )} no cuenta con ${$getVisibleNames(
                                    'teaching.evaluationcriteriamacro',
                                    true,
                                    'Criterios de Evaluación Macro'
                                  )}`
                                "
                              >
                                N/A
                              </span>
                            </template>
                          </b-button>
                          <b-modal
                            :id="`evaluation-criteria-macro-modal-${row.item.evaluation.id}-${section.id}`"
                            :title="`${$getVisibleNames(
                              'teaching.evaluationcriteriamacro',
                              true,
                              'Criterios de Evaluación Macro'
                            )}`"
                            size="lg"
                            hide-footer
                          >
                            <template v-if="allows_crud">
                              <EvaluationCriteriasMacro
                                :EvaluationCriterias="
                                  section.evaluation_criterias_macro
                                "
                                :Section="section"
                                :Options="filterECMacro"
                                @created="SaveECMacro"
                              ></EvaluationCriteriasMacro>
                            </template>
                            <template v-else>
                              <template
                                v-if="
                                  section.evaluation_criterias_macro.length > 0
                                "
                              >
                                <template
                                  v-for="evaluation_criteria in filterECMacro"
                                >
                                  <span
                                    :key="evaluation_criteria.id"
                                    v-if="
                                      section.evaluation_criterias_macro.includes(
                                        evaluation_criteria.id
                                      )
                                    "
                                  >
                                    <span style="position: absolute">
                                      {{ evaluation_criteria.order }}
                                    </span>
                                    <SentenceContainer
                                      style="
                                        padding-left: 5px;
                                        padding-top: 2px;
                                      "
                                      :Sentence="evaluation_criteria"
                                      :boundedTextContainer="true"
                                    ></SentenceContainer>
                                  </span>
                                </template>
                              </template>
                              <template v-else>
                                Esta
                                {{
                                  $getVisibleNames(
                                    "teaching.section",
                                    false,
                                    "Sección"
                                  )
                                }}
                                no cuenta con
                                {{
                                  $getVisibleNames(
                                    "teaching.evaluationcriteriamacro",
                                    true,
                                    "Criterios de Evaluación Macro"
                                  )
                                }}
                                seleccionados.
                              </template>
                            </template>
                          </b-modal>
                        </div>
                        <div>
                          <span
                            >{{
                              $getVisibleNames(
                                "teaching.evaluationcriteriamicro",
                                true,
                                "Criterios de Evaluación Micro"
                              )
                            }}:</span
                          >
                          <b-button
                            size="sm"
                            class="ra-micro-actions ml-1 p-0"
                            variant="none"
                          >
                            <template v-if="filterECMicro.length > 0">
                              <b-icon
                                class="btn-actions"
                                :icon="allows_crud ? 'pencil-square' : 'eye'"
                                @click="
                                  $bvModal.show(
                                    `evaluation-criteria-micro-modal-${row.item.evaluation.id}-${section.id}`
                                  )
                                "
                                v-b-tooltip.v-secondary.noninteractive="
                                  `${
                                    allows_crud ? 'Seleccionar' : 'Ver'
                                  } ${$getVisibleNames(
                                    'teaching.evaluationcriteriamicro',
                                    true,
                                    'Criterios de Evaluación Micro'
                                  )} ${allows_crud ? '' : 'Seleccionados'}`
                                "
                                scale="1"
                              ></b-icon>
                            </template>
                            <template v-else>
                              <span
                                v-b-tooltip.v-secondary.noninteractive="
                                  `La ${$getVisibleNames(
                                    'mesh.egressprofilematter',
                                    false,
                                    'Asignatura'
                                  )} no cuenta con ${$getVisibleNames(
                                    'teaching.evaluationcriteriamicro',
                                    true,
                                    'Criterios de Evaluación Micro'
                                  )}`
                                "
                              >
                                N/A
                              </span>
                            </template>
                          </b-button>
                          <b-modal
                            :id="`evaluation-criteria-micro-modal-${row.item.evaluation.id}-${section.id}`"
                            :title="
                              $getVisibleNames(
                                'teaching.evaluationcriteriamicro',
                                true,
                                'Criterios de Evaluación Micro'
                              )
                            "
                            size="lg"
                            hide-footer
                          >
                            <template v-if="allows_crud">
                              <EvaluationCriteriasMicro
                                :EvaluationCriterias="
                                  section.evaluation_criterias_micro
                                "
                                :Section="section"
                                :Options="filterECMicro"
                                @created="SaveECMicro"
                              ></EvaluationCriteriasMicro>
                            </template>
                            <template v-else>
                              <template
                                v-if="
                                  section.evaluation_criterias_micro.length > 0
                                "
                              >
                                <template v-for="study_unit in filterECMicro">
                                  <strong
                                    :key="study_unit.id"
                                    v-if="
                                      study_unit.options.filter((x) =>
                                        section.evaluation_criterias_micro.includes(
                                          x.id
                                        )
                                      ).length > 0
                                    "
                                    >{{ study_unit.name }}</strong
                                  >
                                  <div
                                    v-for="evaluation_criteria in study_unit.options"
                                    :key="
                                      study_unit.id +
                                      ':' +
                                      evaluation_criteria.id
                                    "
                                  >
                                    <span
                                      v-if="
                                        section.evaluation_criterias_micro.includes(
                                          evaluation_criteria.id
                                        )
                                      "
                                      :key="
                                        study_unit.id +
                                        ':' +
                                        evaluation_criteria.id
                                      "
                                    >
                                      <span style="position: absolute">
                                        {{ study_unit.order }}.{{
                                          evaluation_criteria.order
                                        }}
                                      </span>
                                      <SentenceContainer
                                        style="
                                          padding-left: 15px;
                                          padding-top: 2px;
                                        "
                                        :Sentence="evaluation_criteria"
                                        :boundedTextContainer="true"
                                      ></SentenceContainer>
                                    </span>
                                  </div>
                                </template>
                              </template>
                              <template v-else>
                                Esta
                                {{
                                  $getVisibleNames(
                                    "teaching.section",
                                    false,
                                    "Sección"
                                  )
                                }}
                                no cuenta con
                                {{
                                  $getVisibleNames(
                                    "teaching.evaluationcriteriamicro",
                                    true,
                                    "Criterios de Evaluación Micro"
                                  )
                                }}
                                seleccionados.
                              </template>
                            </template>
                          </b-modal>
                        </div>
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </b-card>
        </template>
      </GenericBTable>
    </div>
    <!-- Modals -->
    <b-modal
      :id="`modal-create-evaluation-${egressProfileMatte.id}`"
      title="Crear Evaluación"
      hide-footer
      size="lg"
    >
      <EvaluationTableForm
        :egressProfileMatte="egressProfileMatte"
        @created="CreateEvaluation"
      ></EvaluationTableForm>
    </b-modal>
  </div>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";

export default {
  name: "EvaluationTable",
  components: {
    GenericBTable: () => import("@/components/reusable/GenericBTable"),
    EvaluationTableForm: () =>
      import("@/components/teaching/Evaluation/EvaluationTableForm"),
    EvaluationCriteriasMacro: () =>
      import("@/components/teaching/Evaluation/EvaluationCriteriasMacro"),
    EvaluationCriteriasMicro: () =>
      import("@/components/teaching/Evaluation/EvaluationCriteriasMicro"),
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
    ObservationInstrumentForm: () =>
      import(
        "@/components/evaluations/ObservationInstrument/ObservationInstrumentForm"
      ),
    QuestionTestForm: () =>
      import("@/components/evaluations/Test/QuestionTestForm"),
  },
  props: {
    egressProfileMatte: {
      type: Object,
      required: true,
    },
    allows_crud: {
      type: Boolean,
    },
  },
  data() {
    return {
      input_search: "",
      selected_instrument: null,
      evaluationList: null,
      instrument_types: [
        { name: "Instrumento Escrito", value: 1 },
        { name: "Instrumento de Observación", value: 2 },
      ],
      selected_instrument_type: null,
      spinner_state: false,
      sortBy: "",
      sortDesc: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
      evaluationCriteriasMacros: names.EVALUATION_CRITERIAS_MACROS,
      evaluationCriteriasMicros: names.EVALUATION_CRITERIAS,
      sectionsEvaluations: names.SECTION_EVALUATIONS,
      studyUnits: names.STUDY_UNITS,
    }),
    filterECMacro() {
      return this.evaluationCriteriasMacros
        .filter((x) => x.matter == this.egressProfileMatte.matter)
        .sort(function (a, b) {
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    },
    sortList() {
      if (this.evaluationList == null) return [];
      let list = this.evaluationList;
      return list.sort(function (a, b) {
        if (
          a.evaluation.question_tests.length > 0 &&
          b.evaluation.question_tests.length > 0
        )
          return (
            new Date(a.evaluation.question_tests[0].start_date) -
            new Date(b.evaluation.question_tests[0].start_date)
          );
        if (
          a.evaluation.observation_instruments.length > 0 &&
          b.evaluation.observation_instruments.length > 0
        )
          return (
            new Date(a.evaluation.observation_instruments[0].start_date) -
            new Date(b.evaluation.observation_instruments[0].start_date)
          );
        if (
          a.evaluation.question_tests.length > 0 &&
          b.evaluation.observation_instruments.length > 0
        )
          return (
            new Date(a.evaluation.question_tests[0].start_date) -
            new Date(b.evaluation.observation_instruments[0].start_date)
          );
        if (
          a.evaluation.observation_instruments.length > 0 &&
          b.evaluation.question_tests.length > 0
        )
          return (
            new Date(a.evaluation.observation_instruments[0].start_date) -
            new Date(b.evaluation.question_tests[0].start_date)
          );
        if (a.evaluation.type < b.evaluation.type) return 1;
        if (a.evaluation.type > b.evaluation.type) return -1;
        if (a.evaluation.name > b.evaluation.name) return 1;
        if (a.evaluation.name < b.evaluation.name) return -1;
        return 0;
      });
    },
    studyUnitsList() {
      if (!this.egressProfileMatte.matter) return [];
      return this.studyUnits
        .filter((x) => x.matter == this.egressProfileMatte.matter)
        .sort(function (a, b) {
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    },
    filterECMicro() {
      let list = [];
      this.studyUnitsList.forEach((study_unit) => {
        const evaluation_criterias = this.evaluationCriteriasMicros.filter(
          (x) => x.study_unit == study_unit.id
        );
        if (evaluation_criterias.length > 0)
          list.push({
            id: study_unit.id,
            name: String("N°" + study_unit.order + ": " + study_unit.name),
            order: study_unit.order,
            options: this.evaluationCriteriasMicros
              .filter((x) => x.study_unit == study_unit.id)
              .sort(function (a, b) {
                if (a.order > b.order) return 1;
                if (a.order < b.order) return -1;
                return a.full_sentence
                  .toLowerCase()
                  .localeCompare(b.full_sentence.toLowerCase());
              }),
          });
      });
      return list;
    },
    evaluationFields() {
      let list = [
        {
          key: "evaluation.name",
          label: "Nombre",
          sortable: true,
          display_column: true,
          tdClass: "text-center",
          thStyle: {
            background: "var(--primary-color) !important",
          },
        },
      ].concat(
        this.user.is_professor
          ? [
              {
                key: "description",
                label: "Descripción",
                sortable: true,
                display_column: false,
                tdClass: "text-left",
                thStyle: {
                  background: "var(--primary-color) !important",
                },
              },
              {
                key: "evaluation.preferred_weighing",
                label: "Ponderación",
                sortable: true,
                display_column: true,
                tdClass: "text-center",
                thStyle: {
                  background: "var(--primary-color) !important",
                },
              },
              {
                key: "evaluation.type_name",
                label: "Tipo de Evaluación",
                sortable: true,
                display_column: true,
                tdClass: "text-center",
                thClass: "text-center",
                thStyle: {
                  background: "var(--primary-color) !important",
                },
              },
              {
                key: "instrument",
                label: "Instrumentos",
                display_column: true,
                tdClass: "text-center",
                thStyle: {
                  background: "var(--primary-color) !important",
                },
              },
              {
                key: "actions",
                label: "",
                display_column: true,
                thClass: "noprint",
                tdClass: "text-center noprint",
              },
            ]
          : this.user.is_student
          ? [
              {
                key: "evaluation.score",
                label: "Puntaje",
                display_column: true,
                tdClass: "text-center",
                thStyle: {
                  background: "var(--primary-color) !important",
                },
              },
              {
                key: "evaluation.weighing",
                label: "Ponderación",
                display_column: true,
                tdClass: "text-center",
                thStyle: {
                  background: "var(--primary-color) !important",
                },
              },
              {
                key: "instrument",
                label: "Instrumentos",
                display_column: true,
                tdClass: "text-center",
                thStyle: {
                  background: "var(--primary-color) !important",
                },
              },
            ]
          : []
      );

      return list;
    },
  },
  methods: {
    hideModal() {
      this.selected_instrument = null;
    },
    editInstrument(id, type) {
      if (type == "question_tests") {
        this.$store.dispatch(names.FETCH_QUESTION_TEST, id).then((response) => {
          this.selected_instrument = response;
          this.$store.dispatch(names.FETCH_QUESTIONS, id).then(() => {
            this.$bvModal.show(`modal-edit-question-test-${id}`);
          });
        });
      }
      if (type == "observation_instruments") {
        this.$store
          .dispatch(names.FETCH_OBSERVATION_INSTRUMENT, id)
          .then((response) => {
            this.selected_instrument = response;
            this.$bvModal.show(`modal-edit-observation-instrument-${id}`);
          });
      }
    },
    CreatedQuestionTest(question_test) {
      const index = this.evaluationList.findIndex(
        (x) => x.evaluation.id == question_test.evaluation
      );
      if (index != -1) {
        this.evaluationList[index].evaluation.question_tests.push(
          question_test
        );
      }
      this.$bvModal.hide(`modal-create-instrument-${question_test.evaluation}`);
    },
    CreatedObservationInstrument(observation_instrument) {
      const index = this.evaluationList.findIndex(
        (x) => x.evaluation.id == observation_instrument.evaluation
      );
      if (index != -1) {
        this.evaluationList[index].evaluation.observation_instruments.push(
          observation_instrument
        );
      }
      this.$bvModal.hide(
        `modal-create-instrument-${observation_instrument.evaluation}`
      );
    },
    slotQuestionTestUpdated(item) {
      this.selected_instrument = null;
      const index = this.evaluationList.findIndex(
        (x) => x.evaluation.id == item.evaluation
      );
      if (index != -1) {
        const index2 = this.evaluationList[
          index
        ].evaluation.question_tests.findIndex((x) => x.id == item.id);
        if (index2 != -1) {
          this.evaluationList[index].evaluation.question_tests[index2] = item;
        }
      }
      this.$bvModal.hide(`modal-edit-question-test-${item.id}`);
    },
    slotObservationInstrumentUpdated(item) {
      this.selected_instrument = null;
      const index = this.evaluationList.findIndex(
        (x) => x.evaluation.id == item.evaluation
      );
      if (index != -1) {
        const index2 = this.evaluationList[
          index
        ].evaluation.observation_instruments.findIndex((x) => x.id == item.id);
        if (index2 != -1) {
          this.evaluationList[index].evaluation.observation_instruments[
            index2
          ] = item;
        }
      }
      this.$bvModal.hide(`modal-edit-observation-instrument-${item.id}`);
    },
    evaluationPercentage(A, B) {
      return (A / B) * 100;
    },
    getMaxWeighing(section) {
      let list = this.sectionsEvaluations.filter(
        (x) => x.section == section.section
      );
      if (list.length == 0) return 100;
      let weighing = 100;
      list.forEach((x) => {
        weighing = weighing - x.weighing;
      });
      return parseInt(weighing);
    },
    instrumentWeighing(score, evaluations) {
      let maxScore = 0;
      evaluations.question_tests.forEach((x) => {
        maxScore += x.score;
      });
      evaluations.observation_instruments.forEach((x) => {
        maxScore += x.score;
      });
      if (maxScore == 0) return 0;
      return (score / maxScore) * 100;
    },
    redirectToInstrument(evaluation_id) {
      this.$router.push({
        name: "EvaluationView",
        params: { evaluation_id: evaluation_id },
      });
    },
    redirectToQuestionTest(evaluation_id, question_test_id) {
      this.$router.push({
        name: "EvaluationView",
        params: {
          evaluation_id: evaluation_id,
          question_test_id: question_test_id,
        },
      });
    },
    redirectToObservationInstrument(evaluation_id, observation_instrument_id) {
      this.$router.push({
        name: "EvaluationView",
        params: {
          evaluation_id: evaluation_id,
          observation_instrument_id: observation_instrument_id,
        },
      });
    },
    patchSectionEvaluation(section) {
      if (
        !isNaN(section.id) &&
        section.weighing >= 0 &&
        section.weighing <= 100 &&
        !isNaN(parseInt(section.weighing))
      ) {
        let payload = {
          section_evaluation_id: section.id,
          item: {
            show_in_dashboard: section.show_in_dashboard,
            show_to_students: section.show_to_students,
            show_stats: section.show_stats,
            weighing: section.weighing,
          },
        };
        this.spinner_state = true;
        this.$store
          .dispatch(names.PATCH_SECTION_EVALUATION, payload)
          .then((response) => {
            if (response) {
              this.spinner_state = false;
              toast(
                this.$getVisibleNames(
                  "teaching.evaluation",
                  false,
                  "Evaluación"
                ) + " actualizada."
              );
            }
          });
      }
    },
    askForDeleteEvaluation(evaluation_id) {
      this.$swal({
        title: `<p>¿Está seguro de que desea eliminar la <b>${this.$getVisibleNames(
          "teaching.evaluation",
          false,
          "Evaluación"
        ).toLowerCase()} y sus instrumentos creados</b>?</p>`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(names.DELETE_EVALUATION, evaluation_id)
            .then(() => {
              setTimeout(() => {
                this.fetchEvaluationTable();
              }, 250);
            });
        }
      });
    },
    CreateEvaluation(evaluation, section_evaluation) {
      if (isNaN(evaluation.id)) {
        this.$store
          .dispatch(names.POST_EVALUATION, evaluation)
          .then((response) => {
            let count = 0;
            section_evaluation.forEach((section) => {
              count++;
              let payload = {
                section: section,
                evaluation: response.id,
                evaluation_criterias_micro: [],
                evaluation_criterias_macro: [],
                weighing: response.preferred_weighing,
                show_in_dashboard: false,
                show_to_students: false,
                show_stats: false,
              };
              this.$store
                .dispatch(names.POST_SECTION_EVALUATION, payload)
                .then(() => {
                  if (section_evaluation.length == count) {
                    setTimeout(() => {
                      this.fetchEvaluationTable();
                    }, 500);
                  }
                });
            });
            toast(
              this.$getVisibleNames(
                "teaching.evaluation",
                false,
                "Evaluación"
              ) + " creada."
            );
            this.$bvModal.hide(
              `modal-create-evaluation-${this.egressProfileMatte.id}`
            );
          });
      }
    },
    UpdateEvaluation(evaluation, section_evaluation, SectionEvaluations) {
      if (!isNaN(evaluation.id)) {
        this.$store.dispatch(names.UPDATE_EVALUATION, evaluation).then(() => {
          section_evaluation.forEach((x) => {
            const index = SectionEvaluations.findIndex(
              (section) => section.section == x
            );
            if (index == -1) {
              const payload = {
                section: x,
                evaluation: evaluation.id,
                evaluation_criterias_micro: [],
                evaluation_criterias_macro: [],
                weighing: evaluation.preferred_weighing,
                show_in_dashboard: false,
                show_to_students: false,
                show_stats: false,
              };
              this.$store.dispatch(names.POST_SECTION_EVALUATION, payload);
            }
          });
          SectionEvaluations.forEach((section) => {
            const index = section_evaluation.findIndex(
              (x) => x == section.section
            );
            if (index == -1) {
              this.$store.dispatch(names.DELETE_SECTION_EVALUATION, section.id);
            }
          });
          this.$bvModal.hide(`modal-edit-evaluation-${evaluation.id}`);
        });
        setTimeout(() => {
          this.fetchEvaluationTable();
        }, 250);
      }
    },
    SaveECMacro(ECMacro_ids, section) {
      if (!isNaN(section.id)) {
        let payload = {
          section_evaluation_id: section.id,
          item: {
            evaluation_criterias_macro: ECMacro_ids,
          },
        };
        this.$store
          .dispatch(names.PATCH_SECTION_EVALUATION, payload)
          .then(() => {
            this.$bvModal.hide(
              `evaluation-criteria-macro-modal-${section.evaluation}-${section.id}`
            );
            const index = this.evaluationList.findIndex(
              (x) => x.evaluation.id == section.evaluation
            );
            if (index != -1) {
              const index2 = this.evaluationList[
                index
              ].evaluation.section_evaluations.findIndex(
                (x) => x.id == section.id
              );
              if (index2 != -1) {
                this.evaluationList[index].evaluation.section_evaluations[
                  index2
                ].evaluation_criterias_macro = ECMacro_ids;
              }
            }
          });
      }
    },
    SaveECMicro(ECMicro_ids, section) {
      if (!isNaN(section.id)) {
        let payload = {
          section_evaluation_id: section.id,
          item: {
            evaluation_criterias_micro: ECMicro_ids,
          },
        };
        this.$store
          .dispatch(names.PATCH_SECTION_EVALUATION, payload)
          .then(() => {
            this.$bvModal.hide(
              `evaluation-criteria-micro-modal-${section.evaluation}-${section.id}`
            );
            const index = this.evaluationList.findIndex(
              (x) => x.evaluation.id == section.evaluation
            );
            if (index != -1) {
              const index2 = this.evaluationList[
                index
              ].evaluation.section_evaluations.findIndex(
                (x) => x.id == section.id
              );
              if (index2 != -1) {
                this.evaluationList[index].evaluation.section_evaluations[
                  index2
                ].evaluation_criterias_micro = ECMicro_ids;
              }
            }
          });
      }
    },
    fetchEvaluationTable() {
      this.$restful
        .Get(
          `/teaching/get_user_evaluations_per_epm/?egress_profile_matter=${this.egressProfileMatte.id}`
        )
        .then((response) => {
          this.evaluationList = response;
          response.forEach((x) => {
            this.$store.dispatch(
              names.FETCH_SECTION_EVALUATIONS,
              x.evaluation.id
            );
          });
        });
    },
    // questionTest(instrument_id) {
    //   let question_test = null;
    //   console.log(instrument_id);
    //   return question_test;
    // },
    askForDeleteInstrument(evaluation_id, instrument_type, instrument_id) {
      this.$swal({
        title: "¿Está seguro de que desea eliminar el Instrumento?",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value && instrument_type == "question_test") {
          this.$store
            .dispatch(names.DELETE_QUESTION_TEST, instrument_id)
            .then(() => {
              const index = this.evaluationList.findIndex(
                (x) => x.evaluation.id == evaluation_id
              );
              if (index != -1) {
                const question_test_index = this.evaluationList[
                  index
                ].evaluation.question_tests.findIndex(
                  (element) => element.id == instrument_id
                );
                if (question_test_index != -1)
                  this.evaluationList[index].evaluation.question_tests.splice(
                    question_test_index,
                    1
                  );
              }
              toast("Instrumento eliminado.");
            });
        } else if (
          result.value &&
          instrument_type == "observation_instrument"
        ) {
          this.$store
            .dispatch(names.DELETE_OBSERVATION_INSTRUMENT, instrument_id)
            .then(() => {
              const index = this.evaluationList.findIndex(
                (x) => x.evaluation.id == evaluation_id
              );
              if (index != -1) {
                const question_test_index = this.evaluationList[
                  index
                ].evaluation.observation_instruments.findIndex(
                  (element) => element.id == instrument_id
                );
                if (question_test_index != -1)
                  this.evaluationList[
                    index
                  ].evaluation.observation_instruments.splice(
                    question_test_index,
                    1
                  );
              }
              toast("Instrumento eliminado.");
            });
        }
      });
    },
  },
  created() {
    this.fetchEvaluationTable();
    this.$store.dispatch(names.FETCH_TAXONOMIES);
    this.$store.dispatch(
      names.FETCH_EVALUATION_CRITERIAS_MACROS,
      this.egressProfileMatte.matter
    );
    this.$store.dispatch(names.FETCH_SECTIONS, {
      egress_profile_matter_id: this.egressProfileMatte.id,
    });
    this.$store.dispatch(
      names.FETCH_STUDY_UNITS,
      this.egressProfileMatte.matter
    );
    this.$store.dispatch(names.FETCH_EVALUATION_CRITERIAS, {
      matter_id: this.egressProfileMatte.matter,
    });
  },
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>

<style scoped>
.title-header {
  padding: 0 2rem;
}
.card-left {
  border-right: 2px solid var(--secondary-color);
}
.btn-add-evaluation {
  margin-right: auto !important;
  margin-left: 1em !important;
  display: block;
}
.w-15 {
  width: 15%;
}
.evaluation-edit,
.evaluation-delete {
  background-color: var(--kl-primary-button-color);
  color: var(--secondary-font-color);
  width: 5em;
  margin-right: 0 !important;
  max-height: 30px;
}
.evaluation-edit:hover {
  background: var(--kl-primary-button-hover-color);
}
.evaluation-edit {
  margin-right: 0.5em;
}
.evaluation-delete {
  margin-left: 0.5em;
  background-color: #dc3545 !important;
}
.evaluation-delete:hover {
  background-color: #e45d6b !important;
}
/* .btn-details {
  position: relative;
}
.btn-instrument{
  position: relative;
  top: 35px;
} */
.btn-details,
.btn-instrument {
  display: table;
  margin: 0 auto;
  min-width: 10.5em;
  background-color: var(--kl-primary-button-color) !important;
  color: #fff;
}
.btn-details:hover,
.btn-instrument:hover {
  background-color: var(--kl-primary-button-hover-color) !important;
}
.label-weighing {
  font-size: var(--primary-font-size) !important;
  max-width: max-content;
  min-width: fit-content !important;
  margin-top: 0.36em;
}
.form-group {
  margin: 0%;
  padding: 0%;
}
.campus-div {
  background-color: var(--primary-color);
  color: white;
  max-width: fit-content;
  border-radius: 3px;
  margin: 0.1em 0.1em;
  padding: 0 2px;
  font-size: var(--thirth-font-size);
}
.btn-actions {
  cursor: pointer;
}
.th-class {
  background: var(--primary-color) !important;
}
.form-input-container {
  display: flex;
}
#input-weighing {
  width: 8.5em;
  margin-left: 1.1em;
}
.ra-micro-actions .btn-actions {
  margin-left: 75%;
}
.left-panel {
  width: 35%;
}
.right-panel {
  width: 65%;
}
.weighing-spinner {
  margin-top: 0.2rem;
  margin-left: 0.3rem;
  width: 1.8rem;
  height: 1.8rem;
}
.div-href {
  color: #007bff;
}
.div-href:hover {
  color: #0057b1 !important;
  text-decoration: underline;
  cursor: pointer;
}
</style>